/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;1,100;1,200&family=Inter:wght@500;700&family=Open+Sans:wght@600&display=swap";

body {
  font-family: "IBM Plex Sans" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  background-color: #fff;
  color: #000000;
}

p {
  font-family: "IBM Plex Sans" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: #000000;
}

.pl-8 {
  padding-left: 8px;
}

.ml-2 {
  margin-left: 15px;
}

.form-group span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.form-group.drop-btn span {
  width: 100%;
}

.text-blue {
  color: #01147c;
  /* font-family: 'Space Grotesk'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.form-control:focus {
  color: none;
  background-color: none;
  border: 1px solid rgba(0, 0, 0, 0.26);
  outline: 0;
  box-shadow: none;
}

.text-header-purple {
  font-family: "Space Grotesk", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #01147c !important;
  font-size: 20px;
}

.btn.btn-cancel,
.btn-back {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  padding: 8px 20px;
  width: 100px !important;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  color: #01147c;
}

.btn.btn-cancel,
.btn-back:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
}

.btn.btn-save {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 35px;
}

.btn.btn-save:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 35px;
}

.btn.btn-continue {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: white;
  padding: 8px 30px;
  font-weight: 400;
  font-size: 14px;
  position: relative;
}

.p-button.p-component.btn.btn-continue:hover {
  background: #01147c;
  border: 1px solid #01147c;
  color: white;
}
.btn.btn-deposit {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: white;
  padding: 5px 28px;
  font-weight: 400 !important;
  font-size: 14px;
}
.btn.btn-deposit:hover {
  background: #01147c;
  border: 1px solid #01147c;
  color: #01147c;
}
.btn.btn-deposit:active {
  background: #01147c;
  border: 1px solid #01147c;
  color: #01147c !important;
}
.document-main-div .p-button {
  /* border-radius: 50% !important; */
  margin-left: 30px;
}
.btn.btn-continue .pi-plus,
.btn.btn-continue .pi-refresh {
  font-size: 11px;
  position: absolute;
  left: 12px;
  top: 13px;
}

.form-group .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: none;
  border-color: none;
}

.btn .p-button:enabled:hover {
  background: none;
  color: none;
}

.btn.btn-continue:hover {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  color: #ffffff;
}

.btn.btn-ok {
  align-items: center !important;
  margin-left: 162px !important;
}

.color-red {
  color: #eb2929;
}

#content {
  width: 100%;
  height: 100vh;
  transition: all 0.3s;
  background-image: url(../../assets/images/bg-img-small.svg),
    url(../../assets/images/contrnt-bg-img.svg);
  background-position: bottom left, bottom right;
  background-repeat: no-repeat;
  position: relative;
}

.right-tab-section {
  position: relative;
  display: block;
  box-sizing: border-box;
}
.full-scroll {
  position: relative;
  display: block;
  box-sizing: border-box;
}
.heading-section {
  position: static;
  display: block;
  width: 100%;
  padding: 10px 0px;
  background-color: #ffffff;
  text-align: center;
  z-index: 99;
}
.datatable-doc-demo{
  padding: 0px 20px;
}
.user-heading.heading-section {
  padding: 10px 20px;
}
/* .container-fluid.acc-screen{
    padding-left: 100px;
} */
.acc-screen {
  /* height: 100vh; */
  padding: 0px 0px 100px 0px;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

.form-group {
  margin-bottom: 18px;
  position: relative;
}

.partner-btns {
  justify-content: center !important;
  position: relative;
}

.form-control.form-control-sm {
  /* width: 500px;
    height: 38px; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 7px;
}

.dropdown-acc input {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  border-radius: 7px !important;
  font-family: "primeicons";
  height: 40px;
}

.pi-chevron-down:before {
  content: "\e902";
  text-align: center;
  position: absolute;
  top: -6px;
  right: 2px;
  font-family: "primeicons" !important;
  color: #01147c;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  height: 21px;
  width: 100%;
  position: absolute;
  font-family: "IBM Plex Sans";
  right: 0px;
  top: 5px;
  padding-right: 10px;
  border: 0px solid #01147c !important;
  border-radius: 2px !important;
  background-color: transparent;
}

.mb-0 {
  margin-bottom: 0px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: none;
}

.form-group .pi-chevron-down:before {
  content: "\e902";
  text-align: center;
  position: absolute;
  top: -3px;
  right: 15px;
  font-family: "primeicons" !important;
  color: #01147c;
}

.form-group .p-inputwrapper-focus {
  border: none;
  background: transparent;
}

.text-blue-address {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px !important;
  line-height: 22px;
  margin-bottom: 10px;
  color: #01147c !important;
}
.text-blue-address.text-blue-address {
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}

.p-autocomplete-dd .p-autocomplete-input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.physical-address-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
      margin-bottom: 12px;

}

.physical-address-text .text-blue-address {
  margin-bottom: 7px;
}

.same-address-text {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
}

.register-main-div .form-check-input {
  appearance: auto !important;
  vertical-align: middle !important;
  position: relative;
  top: -10%;
}

.pd-10 {
  padding-top: 10px;
}
/* .register-main-div .form-group.form-check.login-checkbox {
    margin-left: 9% !important;
} */
.form-group .p-calendar {
  width: 100%;
}
.form-group .p-calendar .pi-calendar {
  line-height: normal;
}
.form-group .p-calendar .p-button {
  background-color: #01147c;
  border: none;
  height: 32px;
}
.form-group .p-calendar .p-button:hover {
  background-color: #01147c;
}
.form-group .p-calendar .p-button:enabled:active {
  background: #01147c;
  color: #ffffff;
  border-color: none;
}
.form-group .p-calendar .p-button:active {
  background-color: #01147c;
}

.form-group .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}
.form-group .p-calendar .p-inputtext:enabled:hover {
  border: 1px solid #ced4da !important;
}
.form-group .p-inputtext:enabled:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.pi-calendar:before {
  font-size: 12px;
}

.document-main-div .documents-text {
  position: relative;
}

.document-main-div .upload-documents {
  position: absolute;
  top: 33px;
}

.form-group.form-check.register-checkbox {
  margin-left: 12% !important;
}

.p-component.dropdown-acc {
  width: 100% !important;
  position: relative;
  display: block;
}

.info-section-second {
  padding-right: 45px;
  padding-left: 75px;
}

.info-section {
  padding-right: 45px;
  padding-left: 45px;
}

.info-section .first-document-upload .pi {
  /* font-size: 22px; */
  color: #01147c;
}
.info-section .first-document-upload .pi-trash:before {
  font-size: 15px;
  color: #01147c;
}

.info-section-border {
  border-right: 1px solid rgba(0, 0, 0, 0.13);
}

.upload-document {
  position: relative;
}

.css-1kuq5xv-MuiButtonBase-root-MuiIconButton-root {
  color: #01147c !important;
}

.document-main-div .css-i4bv87-MuiSvgIcon-root {
  font-size: 2rem;
}

.error-msg {
  color: red !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 15px !important;
}

.acc-screen .form-group .error-msg {
  position: absolute;
  left: 0;
  bottom: -16px;
}

.form-group.login-form-input input::placeholder {
  color: gray !important;
  font-family: "IBM Plex Sans";
}

.p-autocomplete-dd .p-autocomplete-input::placeholder {
  font-family: "IBM Plex Sans";
  color: gray !important;
  font-size: 13px !important;
}

/* .form-group.login-form-input input::placeholder:hover {
  color: rgba(2, 18, 132, 0.2) !important;
} */

.row.basic-info-div {
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.btn-continue .p-button-label {
  font-weight: 500 !important;
}
.btn .p-button-label {
  font-weight: 500;
}
.bottom-btns {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

/* .p-button:enabled:hover {
  background: transparent;
} */
.ankertag {
  margin-top: -30px;
  margin-left: 61px;
}

/* @media (min-width: 1400px){
  .info-section-second{
    padding-right: 130px !important;
    padding-left:  130px !important;
  }
  .info-section {
    padding-right: 130x !important;
    padding-left: 130px !important;
  }
} */

.Imgupload-buttonbar {
  display: flex;
  align-items: center;
  height: 150px;
  max-height: 150px;
}
.Imgupload-buttonbar.myfileupload-buttonbar .col-md-6 {
  position:absolute;
  display: flex;
}

.myfileupload-buttonbar {
  display: flex;
  align-items: flex-start;
}

.myfileupload-buttonbar input {
  position: absolute;
  top: 0;
  right: 0;
  /* left: -20%; */
  margin: 0;
  border: solid transparent;
  border-width: 0 0 100px 200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -o-transform: translate(250px, -50px) scale(1);
  -moz-transform: translate(-300px, 0) scale(4);
  direction: ltr;
  cursor: pointer;
}

.msg {
  font-size: 20px !important;
  margin-bottom: -26px;
  margin-top: 11px;
}

.myui-button {
  /* position: relative; */
  cursor: pointer;
  text-align: center;
  overflow: visible;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 720px) {
  .info-section-second {
    padding-right: 75px !important;
    padding-left: 100px !important;
  }

  .info-section {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}



.p-dialog .p-dialog-content:last-of-type {
  border-radius: 6px;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
  overflow-y: hidden;
}

.myfileupload-buttonbar .p-button {
  height: 47px;
}

.mr-10 {
  margin-right: 10px;
}

.requiredBlue-img {
  height: 13px !important;
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0px 0px !important;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  padding-left: 10px !important;
  padding-top: 7px !important;
  line-height: 22px !important;
  font-size: 13px !important;
}

.spinner-class {
  position: fixed;
  left: 57.5%;
  top: 55.5%;
  transform: translate(-55.5%, -57.5%);
  z-index: 999;
}


.p-progress-spinner-circle {
  stroke: #01147c !important;
}

.acc-screen .form-group input {
  height: 32px;
  display: flex;
  width: 100%;
  flex: 100%;
  font-size: 13px !important;
  line-height: 16px;
  padding: 8px 10px;
  font-family: "IBM Plex Sans" !important;
  color: #000000;
}

.acc-screen .form-group input::placeholder {
  color: #acacac !important;
  font-size: 13px;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  height: 38px !important;
  line-height: 40px !important;
  font-size: 13px !important;
  font-family: "IBM Plex Sans" !important;
  color: #000000;
}

.p-multiselect .p-multiselect-label {
  padding: 0px 20px;
  padding: 0px 12px;
}

.p-multiselect .p-multiselect-label.p-placeholder::placeholder {
  color: gray;
}

.p-inputtext::placeholder {
  color: gray;
}

.p-multiselect-trigger-icon.p-c.pi.pi-chevron-down:before {
  text-align: center;
  position: absolute;
  top: 3px;
  right: 13px !important;
  font-family: "primeicons" !important;
  color: #01147c;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  height: 38px !important;
  line-height: 40px !important;
  font-size: 13px !important;
  font-family: "IBM Plex Sans" !important;
  color: #000000;
}

.p-multiselect.p-component.dropdown-acc {
  height: 38px !important;
  line-height: 40px !important;
  font-size: 13px !important;
  font-family: "IBM Plex Sans" !important;
  color: #000000;
}

.p-multiselect-header {
  display: none;
}

.p-checkbox {
  display: none;
}

.dashboard {
  overflow: hidden;
}

.p-progress-spinner-svg {
  width: 45%;
  height: 45%;
}

.register-address {
  display: flex;
  align-items: center;
}

.register-address .form-check-label {
  margin-left: 10px;
  font-size: 14px;
  line-height: 18px;
}

.register-address input {
  width: 14px;
}

.button-section {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
  margin: 0px;
}

.second-btn {
  margin: 20px;
}

.btn.btn-cancel:hover,
.btn-back:hover {
  border: 1px solid #01147c;
  background: transparent;
}

.p-button .btn-cancel,
.p-button .btn-back:enabled:hover {
  background: #ffff;
}

.p-button:enabled:hover {
  background: transparent;
  color: #01147c;
  border-color: 01147c;
}

.bottom-btns {
  width: 80%;
  margin-right: -40px;
}

.myfileupload-buttonbar .p-button.document-upload {
  padding: 10px 10px;
  height: 32px;
  margin: 0px;
  width: 100px;
  justify-content: center;
  background: #f5f4f4;
  text-align: center;
  font-size: 14px;
  text-align: center;
  color: #01147c;
  line-height: 17px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  font-weight: 500;
  margin-top: 2px;
  position: relative;
}
.myfileupload-buttonbar .p-button.document-upload:hover {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.26);
}

.myfileupload-buttonbar .p-button.document-upload .pi-spinner {
  line-height: normal;
  left:18px;
  font-size: 12px;
  position: absolute;
  margin-right: 5px;
}

.uploadimg {
  max-width: 150px;
  max-height: 150px;
}


.info-section .textarea-wrap .text-area::placeholder {
  color: rgb(159, 159, 159) !important;
  
  font-size: 14px !important;
  line-height: 20px;
}


.delete-doc {
  width: auto;
  background: transparent;
  border: none;
  margin-top: -4px;
}
.delete-doc.upldoc {
  margin-top: 0px;
}

.doc-upload-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#fileupload {
  display: flex;
  align-items: flex-start;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
}

.myfileupload-buttonbar .document-upload {
  height: auto;
  margin-left: 0px;
}

.file-name {
  margin-right: 10px;
  width: 100px;
  display: block;
  overflow: hidden;
}

.pdf-btn {
  border: none;
  display: flex;
  position: relative;
  width: auto;
  min-width: 100px;
  max-width: 400px;
}
.pdf-btn p {
  font-size: 12px;
  line-height: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  margin-right: 10px;
}

.pdf-btn img {
  width: 20px;
}
/* Documents style */
.info-section .text-area {
  font-size: 14px;
  padding: 10px;
  max-height: 150px;
}


.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: gray;
}
.p-inputtext:enabled:hover {
  border-color: gray;
}
.document-main-div .info-section span {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: normal;
}
.document-main-div .info-section .btn-next .pi-spin {
  line-height: normal;
  left: 12px;
  font-size: 12px;
  position: absolute;
  margin-right: 5px;
}

/* Tooltip text */
.delete-tooltip {
  visibility: hidden;
  width: auto;
  background-color: rgb(244, 244, 244);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px 9px;
  font-size: 12px;
  right: -60px;
  top: -10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.doc-tooltip {
  visibility: hidden;
  width: auto;
  background-color: rgb(101, 112, 175);
  color: #fff;
  text-align: center;
  padding: 5px 9px;
  font-size: 12px;
  right: -130px;
  top: -10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.delete-tooltip::after {
  content: " ";
  position: absolute;
  top: 50%; /* At the bottom of the tooltip */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(244, 244, 244) transparent transparent transparent;
}
.doc-tooltip::after {
  content: " ";
  position: absolute;
  top: 50%;
  /* At the bottom of the tooltip */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(101, 112, 175) transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.delete-doc:hover .delete-tooltip {
  visibility: visible;
}

.pdf-btn:hover .doc-tooltip {
  visibility: visible;
}

.document-main-div .info-section .file-name {
  max-width: 150px;
  min-width: 120px;
  color: #01147c;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Curency-section style */

.recevie-currency {
  width: 90%;
  margin: 0 auto;
  position: relative;
  padding-top: 10px;
}
.currenyerror-msg {
  position: absolute;
  margin: 0 auto;
  width: 100%;
}
.recevie-currency h3 {
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  color: #01147c;
  margin-bottom: 25px;
}

/* toggle switch style */

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #01147c;
  width: 40px;
  height: 18px;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch .p-inputswitch-slider {
  background: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 30px;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch .p-inputswitch-slider:before {
  background: #01147c;
  width: 15px;
  height: 15px;
  left: 1px;
  top: 10.2px;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.switch-style .p-inputswitch-checked .p-inputswitch-slider:before {
  width: 15px;
  height: 15px;
  left: 4px;
  background-color: #ffff;
}

.switch-style
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: #01147c;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: transparent;
}

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch.p-focus .p-inputswitch-slider {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  border: none;
  outline: none;
  box-shadow: none;
}

.p-inputswitch {
  width: 40px;
  height: 18px;
}

/* **************paymentsetting css***************** */
.btn.btn-payment {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 1px;
  padding: 5px 35px;
  font-weight: 500;
  font-size: 15px;
}

.btn.btn-payment-selected {
  background: #01147c;
  color: #fcfcfc;
  border-right: 1px solid #ffff;
  border-left: 1px solid #ffff;
  border-radius: 0px;
  padding: 5px 35px;
  font-weight: 500;
  font-size: 15px;
}

.btn.btn-payment:hover {
  background: #01147c;
  color: #fcfcfc;
  border: 1px solid #01147c;
  padding: 5px 35px;
  font-weight: 500;
  font-size: 15px;
}

.type-of-payment {
  justify-content: center;
  align-items: center;
  display: flex;
}

.payment-screen .form-check-input {
  background: transparent;
  border: 1px solid #01147c;
}

.check-display {
  display: flex;
}

.check-display .yes-check {
  margin-right: 20px;
}

.acc-screen.payment-screen {
  padding-bottom: 100px;
}

.payment-screen .payment-section {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.payment-section.fees-next {
  margin-top: 20px;
  padding-right: 40px;
    padding-left: 40px;
}
.payment-screen .payment-section .col-md-2 {
  flex: 0 0 auto;
  width: 24%;
}
.payment-screen h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 10px;
}

.payment-screen .form-check-input:checked[type="radio"] {
  background-image: url(../../assets/images/checkeclipse.svg);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: center;
  background-size: 11px;
}

.payment-screen .form-check-label {
  font-size: 16px;
}

/* ***************Fees css************* */
.fees-tab {
  width: 90%;
  margin: 0 auto;
  padding: 0px 0px;
  padding-top: 10px;
}

.fees-tab h3 {
  text-align: center;
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  color: #01147c;
  margin-bottom: 25px;
}

.fees-tab table th {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #01147c;
  padding-left: 35px;
  text-align: center;
}

.fees-tab table tr td {
  padding-left: 35px;
}

.fees-tab table th:last-child {
  border: none;
}

.currency-img {
  height: 24px;
}

.currency-ind-img {
  height: 35px;
  padding-top: 3px;
}

.fees-screen .form-group input {
  text-align: right;
  font-weight: 500;
  font-size: 15px !important;
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  height: 30px;
}

.fees-screen table td {
  font-size: 13px;
  padding: 10px;
  font-weight: 400;
}

.fees-screen table th {
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
}

.payment-button-section {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-payment-back {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  padding: 8px 66px;
  font-weight: 500;
  font-size: 15px;
}

.btn-payment-back:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
}

.fees-tab thead tr:first-child {
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
}

.fees-screen .country-flag {
  font-size: 12px;
  width: 100px;
  padding: 10px 10px;
  vertical-align: middle;
}
.country-flag img {
  width: 20px;
  margin-right: 7px;
  vertical-align: middle;
}

.fees-tab .form-group {
  margin-bottom: 0px;
  padding: 0px 15px;
}

.fees-tab .form-group input {
  height: 30px;
  padding: 5px 10px;
}

/* Phone input */

.acc-screen.contact-info .PhoneInputCountryIcon--border {
  border: none;
  padding: 0px;
  box-shadow: none;
  background-color: transparent;
}

.PhoneInputInput {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.PhoneInputCountryIconImg {
  width: 80%;
  margin-top: 1px;
  height: 80%;
}

.acc-screen.contact-info .PhoneInput {
  padding: 0px 10px;
  height: auto;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none;
  border: none;
}

.acc-screen.contact-info .Phone-input {
  padding: 0px;
  display: flex;
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  color: #000000 !important;
  opacity: 0.5 !important;
}

.PhoneInputInput:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.acc-screen.contact-info .PhoneInputCountry {
  padding: 7px 10px;
  border-radius: 6px 0px 0px 6px;
  margin-right: 0px;
  background-color: #e9e9e9;
  border-right: 1px solid gainsboro;
}

.PhoneInputCountrySelect option {
  padding: 10px;
  margin: 5px 0px;
}

.dropdown-phn {
  background-color: #01147c;
}

.personal-details .form-group .error-msg {
  padding-left: 14px;
}

.form-group .p-calendar .pi-calendar {
  line-height: normal;
}

.form-group .p-calendar .p-button {
  background-color: #01147c;
  border: none;
  height: 32px;
}

.form-group .p-calendar .p-button:hover {
  background-color: #01147c;
}

.form-group .p-calendar .p-button:enabled:active {
  background: #01147c;
  color: #ffffff;
  border-color: none;
}

.form-group .p-calendar .p-button:active {
  background-color: #01147c;
}

.form-group .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.form-group .p-calendar .p-inputtext:enabled:hover {
  border: 1px solid #ced4da !important;
}

.form-group .p-inputtext:enabled:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.pi-calendar:before {
  font-size: 12px;
}

.form-group .p-button:enabled:active {
  background: transparent;
  color: #ffffff;
  border-color: none;
}

.p-button:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #ffffff;
  border-color: none;
}

/* POPUP STYLE */

.popup-screen {
  width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.popup-screen .p-dialog-content {
  width: 450px;
  padding-bottom: 0px;
  padding-top: 20px;
}

.popup-img {
  margin-bottom: 5px;
  width: 40px;
}

.popup-screen .p-dialog-content .awesome-text {
  font-weight: 600 !important;
  font-size: 22px !important;
  color: #01147c !important;
  line-height: 32px !important;
  padding-bottom: 20px;
  border-bottom: 2px solid #01147c;
  margin-bottom: 15px;
  vertical-align: middle !important;
}

.awesome-text h2 {
  font-weight: 600;
  line-height: 32px;
}

.popup-screen .text-center {
  margin-bottom: 20px;
}

.popup-screen .text-center p {
  font-size: 18px;
  line-height: 23px;
}

/* User-management-popup */
.acc-screen.user-dialog {
  height: auto;
  padding: 0px;
  position: relative;
  z-index: 999 !important;
}
.user-dialog {
  width: 50%;
  display: block;
  max-height: auto;
  min-height: auto;
  z-index: 999;
}
.user-dialog.edit-user-popup {
  width: 80%;
}

.user-dialog .p-dialog-content {
  padding:0px;
  width: 100%;
}
.user-dialog.edit-user-popup .p-dialog-content {
  padding: 30px 10px 0px 10px;
}
.user-dialog .p-dialog-content h2 {
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
  text-align: center;
}
.user-dialog .user-row {
  margin: 0px -10px;
}

.user-dialog .form-control {
  padding: 5px 10px;
  height: 32px;
  font-size: 13px;
  line-height: 20px;
  display: block;
  width: 100%;
}

.user-dialog .p-autocomplete-dd .p-autocomplete-input {
  padding: 5px 10px;
  height: 32px;
  font-size: 13px;
  line-height: 20px;
  display: block;
  width: 100%;
}

.user-dialog .form-control::placeholder {
  font-size: 13px;
}

.user-dialog .payment-btn-dialog {
  display: flex;
  justify-content: flex-end;
}
.user-dialog .payment-btn-dialog .second-btn {
  margin-right: 0px;
}
.close-btn {
  border: none;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.close-btn img {
  width: 13px;
  height: 13px;
}

#confirm-popup {
  width: 400px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

#confirm-popup .p-dialog-content {
  border-radius: 6px 6px 0px 0px;
  padding-top: 20px;
  padding: 0px;
  margin: 0px;
  justify-content: center;
}

#confirm-popup .p-dialog-content span {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 0px;
  color: #01147c;
}
#confirm-popup .p-dialog-content .p-confirm-dialog-icon {
  margin-right: 5px;
  font-size: 16px;
}

#confirm-popup .p-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

#confirm-popup .p-dialog-footer .p-button {
  border: 1px solid #01147c;
  padding: 4px 30px;
}

#confirm-popup .p-dialog-footer .p-confirm-dialog-reject {
  border-radius: 4px;
  padding: 8px 20px;
  width: 100px;
  background-color: white;
  color: #01147c;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 20px;
}
.p-dialog .p-dialog-footer {
  padding: 0px;
}
#confirm-popup .p-dialog-footer .p-confirm-dialog-accept {
  color: #fff;
  background-color: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  padding: 8px 20px;
  width: 100px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 20px;
}

/* ********************************** */

.paymentsetting-readscreen.acccount-seeting-screen .read-only {
  width: 200px;
  display: block;
  padding: 8px 0px;
}
.paymentsetting-readscreen.acccount-seeting-screen .read-text {
  width: 100%;
  display: table-cell;
  padding: 8px 0px;
  text-align: left;
  padding-left: 40px;
}

.paymentsetting-readscreen .read-only span {
  border: 1px solid #212a2f;
  border-radius: 26px;
  display: block;
  font-size: 14px;
  padding: 9px 10px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #01147c;
  margin-left: 0px;
}

.paymentsetting-readscreen .read-text {
  font-size: 14px;
  line-height: 17px;
  vertical-align: middle;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;

  color: #000000;
}

.read-only-heading {
  font-size: 22px;
  line-height: 25px;
  padding-bottom: 10px;
  margin-bottom: 0px;
  border-bottom: 1px solid black;
}

.acccount-seeting-screen table td .form-check-label {
  font-size: 13px;
  font-weight: 500;
  color: #212a2f;
}

.acccount-seeting-screen table th {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #01147c;
  padding: 10px 0px 10px 15px;
  display: inline-block;
  width: 23%;
  border: none;
}

.namefield-border {
  border: 1px solid #212a2f;
  border-radius: 26px;
  padding: 7px 7px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-check .namefield-border .form-check-input {
  margin: 0px;
  margin-right: 10px;
  border-radius: 50px;
  border-color: #01147c;
  cursor: pointer;
}

.form-check .namefield-border .form-check-input:checked {
  background-color: #01147c;
  border-color: #01147c;
  cursor: pointer;
}

.form-check .namefield-border .form-check-input:checked[type="checkbox"] {
  background-image: url("../../assets/images/icon/checked-icon.png");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.namefield-border.delivery-type {
  max-width: 300px;
  min-width: 200px;
}
.acccount-seeting-screen .form-check-input:checked[type="radio"] {
  background-image: url(../../assets/images/white-tick-check.svg);
  background-repeat: no-repeat;
  background-color: #01147c;
  border: none;
}

.acccount-seeting-screen
  .form-check-input:checked[type="radio"]
  .form-check-label
  :active {
  color: red;
}

.acccount-seeting-screen .form-check-input[type="radio"] {
  margin-top: 6px;
  border: 1px solid #212a2f;
}

.form-check-input:focus {
  box-shadow: none;
}

.acccount-seeting-screen h2 {
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #01147c;
  margin-bottom: 10px;
}

.acccount-seeting-screen td {
  border: none;
  display: inline-block;
  width: 20%;
  padding: 0px;
  margin-right: 30px;
}
.acccount-seeting-screen td .form-check {
  padding: 0px;
}

.acccount-seeting-screen thead tr:first-child {
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
}

.acccount-seeting-screen .user-tab {
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 100px;
}

.wallet-empty-message {
  display: flex;
  justify-content: center;
  text-align: center;
  color: orange;
}

.user-tab {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
}
.facilitator-card {

margin-top: 50px;
}
.user-tab .editbtn {
  background: none;
  border: none;
  padding: 0px;
}

.user-tab .editbtn:hover {
  background: none;
  border: none;
}

.user-tab .editbtn .pi-pencil,
.user-tab .editbtn .pi-trash {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(32, 48, 76, 0.09);
}

.pi-pencil:before,
.pi-trash:before {
  color: #000;
  font-size: 12px;
}

.pi-sort-alt:before {
  content: "\e99e";
  color: #01147c;
  font-size: 13px;
}

.pi-sort-amount-up-alt:before {
  content: "\e9a2";
  color: #01147c;
  font-size: 13px;
}

.pi-sort-amount-down-alt:before {
  content: "\e9a2";
  color: #01147c;
  font-size: 13px;
}

.user-heading {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.user-heading h2 {
  font-size: 17px;
  line-height: 20px;
}

.send-btn {
  text-align: right;
  margin-right: 20px;
}
.btn-back:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
}
.send-btn .btn {
  font-size: 14px;
  line-height: 17px;
  margin-right: 20px;
  position: relative;
}
.deposite.datatable-doc-demo {
  padding: 0px 40px;
}

.datatable-doc-demo table {
  border: none;
}

.datatable-doc-demo .p-datatable .p-datatable-thead > tr > th {
  font-size: 14px;
  color: #01147c;
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
  line-height: 19px;
  padding: 10px 15px;
  font-family: "IBM Plex Sans" !important;
  background: transparent;
  font-weight: 600;
  padding-left: 20px;
}

.datatable-doc-demo .p-datatable .p-datatable-thead > tr > th:hover {
  background: transparent;
}

.p-datatable .p-datatable-tbody > tr {
  background: transparent;
  color: black;
  transition: none;
}

.datatable-doc-demo .p-datatable-wrapper {
  margin-bottom: 15px;
}

.datatable-doc-demo
  .p-datatable
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: transparent;
}

.datatable-doc-demo .p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: none;
}

.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background-color: transparent;
  color: black;
}

.datatable-doc-demo .p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border-bottom: 1px solid #dee2e6;
  padding: 8px 15px;
  font-size: 12px;
  font-family: "IBM Plex Sans" !important;
  background: transparent;
  padding-left: 20px;
}

.datatable-doc-demo .p-paginator {
  background: transparent;
  color: #01147c;
  border: solid #e9ecef;
  border-width: 0;
  justify-content: flex-end;
  padding: 10px 0px;
  border-radius: 6px;
}

.datatable-doc-demo .p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  cursor: pointer !important;
  min-width: 10px;
  height: 10px;
}

.datatable-doc-demo .pi-angle-left:before,
.pi-angle-right:before,
.pi-angle-double-right:before,
.pi-angle-double-left:before {
  color: #01147c;
  opacity: 5;
  cursor: pointer;
}

.datatable-doc-demo .p-paginator-current {
  color: #01147c;
  font-size: 14px;
}

.datatable-doc-demo .p-paginator .p-paginator-pages .p-paginator-page {
  border: 0 none;
  color: #01147c;
  min-width: 30px;
  height: 30px;
  transition: none;
  background-color: gainsboro;
  border-radius: 50%;
}
.datatable-doc-demo .p-paginator .p-paginator-pages .p-highlight {
  background-color: rgb(199, 218, 251);
  border: none;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  box-shadow: none;
}
.datatable-doc-demo .p-paginator .p-paginator-pages .p-highlight:focus {
  border: none;
  box-shadow: none;
}
  .datatable-doc-demo .p-inputwrapper-filled {
  display: none;
} 
.ledgermain .p-component.dropdown-acc { 
  display: block !important;
}

.datatable-doc-demo
  .p-datatable
  .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: transparent;
  color: none;
}

.datatable-doc-demo
  .p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):not(.p-datatable-emptymessage):nth-child(odd) {
  background: transparent;
  color: black;
}

.p-autocomplete-dd .p-autocomplete-input {
  font-family: "IBM Plex Sans" !important;
}

.document-main-div .text-area-row {
  margin-bottom: 50px;
  position: relative;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
  outline: 0 none;
  outline-offset: 0;
}
.Admintab-main .p-tabview .p-tabview-nav li a span:hover {
  color: #01147c !important;
}

.Admintab-main .p-tabview .p-tabview-nav li a:hover {
  color: #01147c !important;
}

.fees-screen .tabview-demo {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  padding: 0px;
}

.fees-screen .tabview-demo .p-tabview .p-tabview-nav {
  padding-left: 45px;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: transparent;
}

.tabview-demo .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #01147c;
  color: #01147c;
}
.document-main-div .text-area-row .col-6 {
  position: relative;
}

.document-main-div .text-area-row .error-msg {
  position: absolute;
}

.tool-tip-info {
  display: flex;
  margin-left: 5px;
}

.tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background-color: #01147c;
  border-color: #2e4294;
  color: #01147c;
}

.custom-target-icon {
  display: none;
  flex-direction: column;
  background-color: rgb(244, 244, 244);
  position: absolute;
  padding: 8px 20px;
  border-radius: 6px;
  font-size: 12px;
  top: 0;
  margin-left: 15px;
  width: auto;
  max-width: 80%;
  z-index: 999;
}

.input-label {
  display: flex;
  font-size: 14px;
  line-height: 27px;
  position: relative;
  align-items: center;
}

.tool-tip-info:hover .custom-target-icon {
  display: block;
  cursor: pointer;
}

.custom-target-icon h4 {
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
}

.custom-target-icon ul {
  margin-top: 7px;
}

.custom-target-icon ul li {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  margin-left: 17px;
  list-style-type: disc;
  margin-bottom: 5px;
}

.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  background: #ffffff;
  border-color: #adb5bd;
  color: black;
}

element.style {
  width: 131px;
  left: 111.344px;
}

.custom-target-icon::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgb(244, 244, 244) transparent transparent;
}

.profile-save-btn {
  display: flex;
  justify-content: end;
  padding-top: 80px;
}

/* *********************AdminPortal************************ */
.Admintab-main {
  padding: 0px 0px 0px 0px;
  width: 100%;
  margin: 0 auto;
}
.Admintab-main
  .p-tabview
  .p-tabview-nav
  li
  .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.Admintab-main .fees-screen .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 12px;
  font-size: 14px;
}

.Admintab-main .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #01147c;
  color: #01147c;
}

.fees-screen table .fees-table th {
  font-size: 14px;
}
.fees-screen .p-tabview-panels {
  padding: 0px 0px;
}
.Admintab-main
  .fees-screen
  .p-tabview
  .p-tabview-nav
  li.p-highlight
  .p-tabview-nav-link {
  font-size: 14px;
  padding: 12px;
}

.Admintab-main .p-tabview-nav-content {
  padding: 0px 20px;
}

.heading-section .dashboard {
  text-align: right !important;
}

.Admintab-main .acc-screen {
  padding: 0px 0px 0px 0px;
}

.Admintab-main .user-tab {
  width: 100%;
}
.Profile-link-text {
  display: flex;
  align-items: flex-end;
  text-align: right;
  justify-content: flex-end;
}
.Profile-link-text .profile-view {
  color: #01147c;
  font-family: "IBM Plex Sans" !important;
  font-size: 12px;
  line-height: 13px;
  cursor: pointer;
  background: white;
  border: 1px solid #01147c;
  padding: 8px 12px;
  border-radius: 6px;
  letter-spacing: normal;
  font-weight: 600;
  align-items: flex-end;
  height: 32px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 15px;
}
.Profile-link-text .profile-view:hover {
  color: #fff;
  background-color: #01147c;
}
.btn.btn-profilelink {
  background: #fcfcfc;
  border: 1px solid #01147c;
  border-radius: 4px;
  color: #01147c;
  padding: 8px 50px;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 15px;
  margin-top: 0%;
}

.data-search-input .p-input-icon-left {
  width: 200px;
  display: flex;
  align-items: center;
}
.data-search-input .p-input-icon-left .p-inputtext {
  padding: 12px 10px 10px 30px;

  width: 200px;
  font-size: 12px;
  display: flex;
  border-color: #01147c;
  height: 30px;
}
.p-input-icon-left .p-inputtext::placeholder {
  font-size: 12px;
  font-family: "IBM Plex Sans" !important;
}

.data-search-input .pi-search {
  font-size: 12px;
  margin-top: -6px;
}
.data-search-input .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

/* .p-filled {
  display: none;
} */

.list-unstyled li {
  margin-bottom: 15px;
}
.send-btn .btn.second-btn-send {
  position: relative;
  margin-right: 0px;
}

.btn-continue .pi {
  font-size: 13px;
  /* margin-right: 5px; */
}

.password-input-icon {
  position: relative;
  color: gray;
}

.login-form-display {
  width: 80%;
  padding-top: 40px;
  padding-bottom: 30px;
}

.datatable-doc-demo .btn-view {
  width: 75px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 17px;
  background-color: #ffff;
  color: #01147c;

  border-color: #01147c;
}
.datatable-doc-demo .btn-view:hover {
  background-color: #01147c;
  color: white;
}
.file-name-delete {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}

.adm-insta-fees {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;
  padding: 0px 40px;
}

.p-datatable .p-column-header-content {
  display: table-cell;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  flex: 0 0 30%;
}
.dashboard .togle-button {
  background: transparent;
}

.Admintab-main .main-tab .p-tabview-panels {
  background: transparent;
  padding: 0px 0px;
}
.Admintab-main .wrapper {
  padding: 0px 0px;
}
.popup-btn-layout {
  display: flex;
  justify-content: center;
}

.edit-content {
  display: flex;
  width: 100%;
  margin: 20px 0px 10px 0px;
  justify-content: flex-end;
  padding: 0px 50px;
}
.edit-payment {
  margin: 0 0px 10px 0px;
  justify-content: flex-end;
}
.edit-content .edit-partner,
.edit-payment .edit-partner {
  width: 80px;
  font-size: 14px;
  padding: 7px 0px;
  color: #01147c;
  font-weight: 700;
  line-height: 17px;
  border: 1px solid #01147c;
  background: transparent;
  position: relative;
}
.edit-content .btn-cancel {
  margin-right: 20px;
}

.edit-content .edit-partner:hover,
.edit-payment .edit-partner:hover {
  background-color: #01147c;
  color: #ffff;
}

.btn-continue.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin: 0;
  position: absolute;
  font-size: 12px;
  left: 10px;
}
.btn.btn-next.p-button .p-button-loading-icon {
  margin: 0;
  position: absolute;
  font-size: 12px;
  left: 10px;
}
.partner-update-popup {
  width: 20%;
  background-color: white;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
}
.popup-body {
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}

.partner-update-popup .awesome-text {
  margin-bottom: 30px;
}
.partner-update-popup .btn.btn-register-ok {
  margin: 0px auto;
}

.fees-error-msg {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.fees-table tr th {
  font-size: 14px;
  color: #01147c;
  font-weight: 600;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.currency-details table tr th {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  color: #01147c;
  font-weight: 700;
  border-top: 1px solid #01147c;
  border-bottom: 1px solid #01147c;
}
.currency-details table tr td {
  font-size: 14px;
  line-height: 17px;
}
.adm-insta-fees .btn.btn-continue {
  font-size: 13px;
  line-height: 17px;
}

.FFpopup-btn {
  display: flex;
  align-items: center;
  justify-content: right !important;
}

.user-edit .error-msg {
  padding-left: 10px;
}
.fees-error {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 90%;
}

.fees-table {
  position: relative;
}

.table-screen .info-section {
  padding: 0px 0px;
}

.save-btn {
  width: 100px !important;
}
.p-button.next-btn,
.btn.next-btn {
  background: #01147c;
  border: 1px solid #01147c;
  border-radius: 4px;
  display: block;
  margin: 20px;
  color: #fff;
  line-height: 17px;
  padding: 10px 20px;
  width: 100px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.p-button.next-btn:hover,
.btn.next-btn:hover {
  background: #01147c;
  color: #fff;
}
.add-user-screen {
  padding-top: 30px;
}
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
  background-color: #fff;
}
.form-control:disabled {
  background-color: #ffff;
  opacity: 0.6;
  color: rgb(0, 0, 0);
}
.fxrate-error-msg {
  color: red !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 22px !important;
}
.hr {
  color: rgba(0, 0, 0, 0.08);
}
.developer-resource {
  width: 100%;
}
.developer-resorce-Screen {
  padding: 0px 0px 200px 0px !important;
}
.developer-resorce-Screen .card {
  background: #fbfbfb;
  box-shadow: 0px 4px 27px rgb(1 20 124 / 19%);
  margin-top: 12px;
  margin-left: 10px;
  height: 48.5%;
}
.developer-resorce-Screen h1 {
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
  font-size: 19px;
}
.developer-resorce-Screen h2 {
  font-weight: 600;
  font-size: 23px;
  text-align: center;
  margin-bottom: 5px;
  color: #01147c;
}
.developer-resorce-Screen p {
  font-size: 14px;
  line-height: 20px;
}
.ql-editor.ql-blank::before {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-size: 13px;
  color: #808080d1;
}

.realtime-payment-inputbox {
  background: #01147c;
  border: none;
  color: white;
  height: 35px;
  text-align: center;
  width: 100%;
}

.realtime-payment-inputbox:focus {
  outline: none;
  box-shadow: none;
}
.partnership-drop .dropdown-acc input {
  height: 31px;
  width: 75%;
  border: 1px solid rgba(1, 20, 124, 0.52) !important;
  border-radius: 1px !important;
}

.partnership-drop .pi-chevron-down:before {
  content: "\e902";
  text-align: center;
  position: absolute;
  top: -2px;
  right: 6px;
  font-family: "primeicons" !important;
  color: #01147c;
}
.partnership-drop .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: none;
}
.inputbox-partnership {
  border: 1px solid rgba(1, 20, 124, 0.52);
  height: 31px;
  margin-right: 1px;
}
.input-with-icon-bank {
  background-image: url("../images/banksvg.png");
  background-repeat: no-repeat;
  background-position: left 23% center;
  background-size: 15px;
  font-weight: 500;
    font-size: 15px;
}
.input-with-icon-realtime {
  background-image: url("../images/relatimepayment.svg");
  background-repeat: no-repeat;
  background-position: left 37% center;
  font-weight: 500;
    font-size: 15px;
}
.input-with-icon-mobilewallet {
  background-image: url("../images/mobilesvg.svg");
  background-repeat: no-repeat;
  background-position: left 25% center;
  background-size: 12px;
  font-weight: 500;
    font-size: 15px;
}
.input-with-icon-cashpickup {
  background-image: url("../images/cashpickupimg.svg");
  background-repeat: no-repeat;
  background-position: left 26% center;
  font-weight: 500;
    font-size: 15px;
}
.partershipdetailsinput {
  width: 47px;
  border: 1px solid rgba(1, 20, 124, 0.52);
  height: 31px;
  margin-right: 1px;
  border-radius: 1px;
  padding: 0px 0px 0px 3px;
  text-align: center;
}
.p-component.dropdown-acc.partner-dropdown {
  display: inline-flex;
}
.p-component.dropdown-acc.partner-dropdown input {
  border: 1px solid rgba(1, 20, 124, 0.52) !important;
  border-radius: 1px !important;
  height: 31px;
  width: 75%;
  border-radius: 1px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.p-component.dropdown-acc.partner-dropdown .p-button:enabled:hover {
  background-color: transparent;
}
.p-component.dropdown-acc.partner-dropdown .pi-chevron-down:before {
  top: 1px;
  right: 13px;
}
.p-component.dropdown-acc.partner-dropdown .p-filled {
  display: flex;
}
.partner-errormsg {
  color: red !important;
  font-weight: 400 !important;
  position: absolute;
  margin-top: 32px;
  font-size: 12px !important;
  line-height: 12px !important;
}
.approve-heading {
  padding-left: 45px;
  padding-right: 45px;
}

.hub-cards {
  padding-top: 20px;
  padding-bottom: 250px;
}

.admin-scrollbar .contain-scroll > div {
  min-height: auto !important;
  max-height: calc(100vh - 100px) !important;
}

.admin-scrollbar .contain-scroll {
  height: auto;
  min-height: 0px !important;
  max-height: calc(100vh - 140px) !important;
}
.right-tab-section .contain-scroll {
  height: auto !important;
  min-height: 0px !important;
  max-height: calc(100vh - 135px) !important;
  overflow-x: hidden !important;
}
.right-tab-section .contain-scroll > div {
  min-height: auto !important;
  max-height: calc(100vh - 140px) !important;
  overflow-x: hidden !important;
  margin-bottom: 0px !important;
}

.full-scroll .contain-scroll {
  height: auto;
  min-height: 0px !important;
  max-height: calc(100vh - 0px) !important;
  overflow-x: hidden !important;
}
.full-scroll .contain-scroll > div {
  min-height: auto !important;
  max-height: calc(100vh - 35px) !important;
  overflow-x: hidden !important;
}

.payment-screen .payment-button-section {
  padding-bottom: 150px;
}
.right-tab-section.transaction-tab .contain-scroll {
  height: auto;
  min-height: 0px !important;
  max-height: calc(100vh - 45px) !important;
  overflow-x: hidden !important;
}
.right-tab-section.transaction-tab .contain-scroll > div {
  min-height: auto !important;
  max-height: calc(100vh - 45px) !important;
  overflow-x: hidden !important;
}

.p-editor-container .p-editor-content .ql-editor {
  line-height: 30px;
}

.text-green {
  color: #09d25d !important;
  font-weight: 600 !important;
}
.text-danger {
  font-weight: 600 !important;
}

.tab-logo-wrapper {
  display: block;
  position: relative;
}

.partner-profile-logo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 20px 0px 20px;
  height: 70px;
  margin-bottom: 10px;
}

.partner-logo-section .partner-logo {
  width: auto;
  max-height: 30px;
  max-width: 120px;
}

.logo-section {
  display: flex;
  justify-content: center;
  padding-left: 40px;
  height: 35px;
  align-items: center;
  border-left: 4px solid white;
}

.partner-name {
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-size: 400;
  margin-right: 20px;
}
.p-button .p-component.notification-icon:hover {
  background: none;
}

.navbar-collapse {
  display: flex !important;
}
.notification-bar {
  display: flex;
  position: relative;
  cursor: pointer;
}
.notification-bar .p-button.p-button-icon-only {
  padding: 0px;
  margin: 0px;
  width: auto;
}
.notification-bar .p-button.p-button-icon-only .pi {
  font-size: 16px;
  color: white;
}

.logo-loading .p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  display: inline-block;
}

.Fees-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 150px;
  font-family: "IBM Plex Sans";
}
.fees-discription {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-bottom: 20px;
  letter-spacing: normal;
}
.Fees-heading h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #000;
  letter-spacing: normal;
  margin-bottom: 20px;
  background: #e0e0e0;
}
.Fees-sending-payment h4 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
  font-weight: 600;
  letter-spacing: normal;
  color: #000;
}



.Fees-details-card .fees-card {
  background-color: #fff;
  margin: 0px 5px;
  flex-direction: column;
  display: flex;
  padding: 10px 20px 15px 20px;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.fees-card.country {
  flex: 10%;
  max-width: 160px;
}
.fees-card.currency {
  flex: 10%;
  max-width: 160px;
}

.fees-card.fixed-fee {
  flex: 15%;
  max-width: 280px;
}

.Fees-details-card .fees-card p {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 7px;
  color: #000;
  font-weight: 500;
}

.Fees-details-card .fees-card h3 {
  font-size: 16px;
  line-height: 22px;
  color: #01147c;
  font-weight: 700;
}
.toggle-country {
  background-color: transparent;
  display: flex;
  flex: 0 0 10%;
  background-color: transparent;
  margin: 0px 0px;
  padding: 10px 10px;
  min-width: 140px;
  border-radius: 4px;
}

.Toggle-btn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.Toggle-btn .toggle-btn.active {
  border: none;
  color: #fff;
}
.Toggle-btn .toggle-btn {
  font-size: 16px;
  height: 35px;
  background-color: #fff;
}

.fees-card input {
  width: 100%;
  font-size: 14px;
  padding: 5px 27px 5px 5px;
  height: 27px;
  font-weight: 600;
  border: 1px solid black;
  line-height: normal;
}
.fees-card input:focus-visible {
  outline: none;
}

.fees-card input::placeholder {
  text-align: right;
}
.switch-field {
  display: flex;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #ffffff;
  color: #01147c;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  padding: 12px 17px;

  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #01147c;
  color: #fff;
  box-shadow: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  padding: 12px 17px;
}

.percent-wrapper {
  position: relative;
}
 .percent-wrapper .feespercent {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 12px;
}
.fees-tabs{
  position: relative;
}
.fees-tabs
.feespercent {
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 12px;
}

.percent-wrapper .feespercent-variable  {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 12px;
}

.register-popup {
  width: 25%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.register-popup.Payment-screen {
  width: 30%;
  padding: 20px 0px 10px;
}
.register-popup.manage-approve-popup {
  text-align: center;
  padding-bottom: 0px;
}
.register-popup .yes-btn-popup {
  width: 100px;
}

.popup-body {
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}
.Payment-screen .awesome-text h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #01147c;
}

.Payment-screen .awesome-text p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
}

.logo-section {
  display: flex;
  justify-content: center;
  padding-left: 40px;
  height: 35px;
  align-items: center;
  border-left: 4px solid white;
}

.partner-logo {
  width: 100%;
  max-height: 30px;
  background-color: white;
}

.Payment-screen .awesome-text {
  margin-bottom: 0px;
}
.payment-screen-btn {
  display: flex;
  width: 300px;
  justify-content: center;
  margin: 0 auto;
}
.payment-screen-btn .btn {
  font-weight: 700;
}
.payment-screen-btn .btn.btn-continue {
  font-weight: 700;
}
.row.card-error-msg {
  justify-content: center;
  width: 97%;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
}
.row.card-error-msg .blank-div {
  display: flex;
  flex: 10%;
  max-width: 160px;
  padding: 0px 0px;
}
.row.card-error-msg .blank-div.toggle-country {
  background-color: initial;
  border-radius: 4px;
  display: flex;
  flex: 0 0 10%;
  margin: 0;
  min-width: 160px;
  padding: 10px;
}
.row.card-error-msg .error-msg-1 {
  display: flex;
  flex: 15%;
  max-width: 280px;
}

.select-all-field {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 15px;
}
.user-tab .table {
  text-align: center;
}

.select-all-field .switch-style {
  margin-right: 10px;
}
.select-all-field p {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #01147c;
}

.adm-insta-fees-table .ledger-screen {
  padding: 0px 20px;
}
.adm-insta-fees-table .last-fixrate-table {
  padding-bottom: 100px;
}
.adm-insta-fees-table {
  padding: 0px 20px;
}
.p-button:focus {
  box-shadow: none;
}

.sla-screen {
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
}

.sla-screen .sla-wrapper {
  flex: 50%;
}
.resource-success-msg {
  color: green !important;
  font-weight: 400 !important;
  position: absolute;
  margin-top: 0px;
  font-size: 12px !important;
  line-height: 22px !important;
}
.login-error-msg {
  color: red !important;
  font-weight: 400 !important;
  position: absolute;
  margin-top: 0px;
  font-size: 12px !important;
  line-height: 22px !important;
}
.login-error-msg.instafees {
  color: red !important;
  font-weight: 400 !important;
  position: relative;
  margin-top: 0px;
  font-size: 12px !important;
  line-height: 22px !important;
  margin-right: 11%;
}
.facilitator-main .editbtn {
  background: none;
  border: none;
  width: 30px;
  margin-right: 10px;
  padding: 0px;
}
.facilitator-main .editbtn:hover {
  background: none;
  border: none;
}
.facilitator-main .editbtn .pi-pencil {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(32, 48, 76, 0.09);
}
.facilitator-main .editbtn .pi-trash {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(32, 48, 76, 0.09);
}
.facilitator-main .editbtn .pi-trash::before {
  font-size: 14px;
}
.facilitator-main .spinner-class {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4%;
  height: 50px !important;
}

.p-dialog hr {
  width: 80%;
  border-top: 3px solid #01147c !important;
  margin-left: 11%;
}

.p-dialog-draggable .p-dialog-header {
  display: none !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #01147c;
  color: #01147c;
}

.button-section.addfeesbtn {
  padding-top: 0px;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 20px;
}
.addfeesbtn .btn.btn-continue .pi-plus {
  top: 14px;
}
.btn.btn-cancel.second-btn.nobtnpopup {
  color: #01147c;
}
.btn.btn-cancel.second-btn.nobtnpopup:hover {
  background-color: transparent;
  color: #01147c;
  border-color: #01147c;
}
.samecurrencyPopup {
  padding: 0px 20px 20px;
}
.bottom-btns .p-button.btn-back:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  border: 1px solid #01147c;
  color: #01147c;
}

.scroll-padding {
  padding-bottom: 50px;
}

.facilitator-main
  .datatable-doc-demo
  .p-datatable.filter-table
  .p-datatable-thead
  > tr:last-child
  > th {
  padding: 7px 0px;
  padding-left: 0px;
}

.facilitator-main .p-fluid .p-inputtext {
  padding: 4px 10px;
  font-size: 12px;
}
.facilitator-main .p-inputtext:focus {
  box-shadow: none;
  outline: none;
  border-color: gray;
}

.p-inputtext::placeholder {
  font-size: 11px;
  line-height: 16px;
  vertical-align: middle;
}

.pi.pi-filter-slash {
  font-size: 12px;
}
.address-main .button-section {
  padding-top: 21px !important;
}
.ql-editor p {
  font-size: 15px;
  font-weight: 400;
}
.p-inputswitch {
  height: 17px;
  width: 37px;
}

.switch-style
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background: #01147c;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: transparent;
}

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch.p-focus .p-inputswitch-slider {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #01147c;
}

.switch-style .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  border: none;
  outline: none;
  box-shadow: none;
}

.status-check.activeinput {
  margin-left: 30px;
}
.form-control[readonly] {
  background-color: #ffff;
  opacity: 0.6;
  color: rgb(0, 0, 0);
}
.acc-screen .form-group .error-msg-upload {
  left: 0;
  position: absolute;
}
.calendar .btn.btn-edit {
  border: 1px solid #01147c;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: #01147c;
  padding: 3px 9px;
  font-weight: 400;
  font-size: 13px;
}
.p-inputtextarea.p-inputtext.p-component.form-control.text-area::placeholder {
  color: #acacac !important;
  font-size: 13px;
  opacity: 0.9; 
}

.ledger-tab{
  margin-top: 20px;
}
.popup-inner-content{
  padding: 30px 20px 0px 20px;
}

.popup-inner-content2{
  padding: 0px 20px 0px 20px;
}
.dashboard-header {
  padding: 20px;
}


.balance-amount {
  position: relative;
  padding: 20px 0px;
  padding-bottom: 100px;
}
.balance{
  position: absolute;
  left: 50%;
  top: 12%;
  transform: translate(-0%, -50%);
}

.acc-screen .form-group .errormsg-limit{
  left: 0;
  position: absolute;
  bottom: -30px;
}
.wallet-table{
  position: relative;
  display: block;
  width: 100%;
  height: 600px;
}
.spinner-class-partnerloader{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

}
.wallet-loader{
  top: 25%;
}

.Logo-wrappper{
  display: flex;
  max-width: 300px;
  max-height: 300px;
  align-items: center;
  border-radius: 6px;
  margin-left: 20px;
} 

.partner-logo-bg{
  display: flex;
background-color: white;
  min-width: 30px;
  max-width: 165px;
  max-height: 50px;
  min-height: 40px;
  align-items: center;
  padding: 0px 10px;


}

.admin-logo{
  display: flex;
  max-width: 155px;
  min-height: 50px;
  max-height: 70px;
  align-items: center;
  padding: 0px 0px 0px 20px;
  border-radius: 6px;
}

.awesome-text .pi-info-circle
{
  margin-right: 5px;
}


.error-card-3{
  justify-content: flex-end;
  position: relative;
  margin: 0 auto;
  display: flex;
  margin-top: 10px;
}

.Fees-details-card-2 {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 69%;
  margin: 0 auto;

}
.Fees-details-card-1 {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 63%;
  margin: 0 auto;

}
.Fees-details-card-3 {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 71%;
  margin: 0 auto;

}
.Fees-details-card-4  {
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  width: 78%;
  margin: 0 auto;
}

.inner-card-wrapper{
  display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.Fees-details-card-2 .error-message-col{
  width: 30.5%;

 }
 .Fees-details-card-1 .error-message-col{
   width: 35%;

  }
  .Fees-details-card-3 .error-message-col{ 
   width: 27.5%;
   line-height: 11px;
  }
  .Fees-details-card-4 .error-message-col{ 
   width:  23.5%;
  
  }

  .Fees-details-card-1 .error-message-col-varible{
    width: 45.5%;
  }
.paymentsetting-readscreen .user-tab{
  padding-bottom: 10px;
}

.approvetable p {
    font-size: 13px;
}
.basicinfomain .button-section {
  padding-top:0px;
}

.manage-approve-popup .popup-text p {
  font-size: 18px;
  line-height: 30px;
  color: #01147c;
}

.p-tabview .p-tabview-panels{
  padding: 0pX;
}
.p-tabview .p-tabview-panels .Add-partner-table{
  padding: 0px  0px;
}

.p-tabview .p-tabview-panels .facilitator-tabs{
  padding-top: 10px;
}

.p-tabview .facilitator-fee .datatable-doc-demo{
  padding: 0px;
}



.Fxrate-popup{
  width: 30%;
  border-radius: 10px;
}

.Fxrate-popup  p{
  font-size: 16px;
  line-height: 23px;

}

.Fxrate-popup .p-dialog-content {
  padding: 30px 30px 30px 30px;
  width: 100%;
  border-radius: 6px 6px 0px 0px;
}
.Fxrate-popup .p-dialog-footer button {
  margin: 0px 0px 0px  20px;
}

.Fxrate-popup .btn-back:hover {
  background: #fcfcfc;
  border: 1px solid #01147c;
  color: #01147c;
}
.Fxrate-popup .p-button:enabled:hover{
  background: #fcfcfc;
  border: 1px solid #01147c;

}

.datatable-doc-demo .balance-amount .col-md-3{
  padding: 0px 0px;
  font-weight: 500;
}

.Admintab-main  .Amount-details{
padding: 10px 10px;
}